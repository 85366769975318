import Image from "next/image";
import Link from "next/link";

import Button from "../button";
import Heading from "../heading";
import SolutionListItem from "../solution-list-item";

import styles from "./browse-solutions.module.scss";

const BrowseSolutions = ({ solutions }) => {
  return (
    <>
      <div className={styles.FindSpaceHeading}>
        <Link href="/solutions" passHref>
          <a>
            <Heading>Find Space</Heading>
          </a>
        </Link>
      </div>

      <div className={styles.SpaceHero}>
        <Image
          src="/images/find-space-hero.jpg"
          alt="Find space"
          width="800"
          height="640"
          layout="responsive"
          objectFit="cover"
        />
      </div>

      <div className={styles.BrowseSpaces}>
        <h3>Browse by solutions</h3>

        <div className={styles.SolutionsList}>
          {solutions.map((solution) => (
            <SolutionListItem key={solution.name} solution={solution} />
          ))}
        </div>

        <div className={styles.ViewAll}>
          <Link href="/solutions" passHref>
            <Button rounded outline small>
              View All Solutions
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default BrowseSolutions;
