import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";

import useViewport, { Viewport } from "../../hooks/useViewport";
import Button from "../button";

import styles from "./video-section.module.scss";

const VideoSection = ({
  title,
  description,
  video,
  youtubeEmbed = null,
  buttonText = "Watch Here"
}) => {
  const [isWatching, setIsWatching] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const device = useViewport();

  const videoRef = useRef();

  const handleWatchVideo = useCallback(() => {
    // This causes a slight delay in switching from thumbnail
    // to the video.
    setIsWatching(true);

    // reset timing
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;

      if (device === Viewport.PHONE) {
        videoRef.current.requestFullscreen();
      }

      videoRef.current.play();
    }
  }, [setIsWatching, device]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowVideo(true);
    }, 20);

    return () => {
      clearTimeout(timeout);
    };
  }, [setShowVideo]);

  if (youtubeEmbed) {
    return (
      <iframe
        className={clsx(styles.YoutubeEmbed)}
        src={youtubeEmbed}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    );
  }

  return (
    <div className={clsx(styles.VideoSection, isWatching && styles.Watching)}>
      <div className={styles.Poster}>
        <div
          className={clsx(styles.VideoContainer, isWatching && styles.Watching)}
        >
          {showVideo && (
            <video
              ref={videoRef}
              autoPlay
              muted={!isWatching}
              controls={isWatching}
              loop={!isWatching}
              alt={video.description}
              playsInline
              preload="none"
            >
              <source src={`${video.url}`} />
            </video>
          )}

          {!isWatching && <div className={styles.VideoOverlay}></div>}
        </div>

        {!isWatching && (
          <>
            <h2>{title}</h2>
            <p className={styles.Description}>{description}</p>

            <Button
              className={styles.PlayButton}
              outline
              rounded
              small
              dark
              onClick={handleWatchVideo}
            >
              {buttonText}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default VideoSection;
