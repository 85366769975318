import Link from "next/link";

import Heading from "../heading";
import NewsPostItem from "../news-post-item";

const NewsHomeColumn = ({ posts }) => {
  return (
    <>
      <div className="text-center">
        <Link href="/news" passHref>
          <a>
            <Heading>News &amp; Stories</Heading>
          </a>
        </Link>
      </div>
      {posts.map((post) => (
        <NewsPostItem key={post.title} post={post} showByline />
      ))}
    </>
  );
};

export default NewsHomeColumn;
