import clsx from "clsx";

import config from "../config";
import { getHomePageBlogArticles } from "../lib/blog-articles";
import { getSolutions } from "../lib/solutions";
import { getCalloutSection } from "../lib/callout-section";
import { getAsset } from "../lib/asset";
import { getSEOPageProps } from "../lib/seo";

import CalloutSection from "../components/callout-section";
import VideoSection from "../components/video-section";
import NewsContinued from "../components/news-continued";
import MeetOurClients from "../components/meet-our-clients";
import SocialMembership from "../components/social-membership";
import BrowseSolutions from "../components/browse-solutions";
import NewsHomeColumn from "../components/news-home-column";
import SeoMeta from "../components/seo-meta";

import styles from "./index.module.scss";

export default function Home({
  articles,
  solutions,
  video,
  memberCalloutSection,
  locationCalloutSection,
  seo
}) {
  const posts = articles.slice(0, 2);

  const postsContinued = [...articles.slice(2)];

  return (
    <>
      <SeoMeta {...seo} />

      <div className="container">
        <div className={styles.WorkspacesBanner}>
          <h1 className={styles.WorkspacesTitle}>Workspaces, Etc</h1>
          {/* <Image
            src="/images/workspaces-etc.png"
            alt="East Room Workspaces, Etc."
            layout="responsive"
            width={714}
            height={97}
            priority
          /> */}
        </div>
      </div>

      <div className={clsx("container", styles.Columns)}>
        <div className={styles.Column}>
          <BrowseSolutions solutions={solutions} />
        </div>

        <div className={styles.Column}>
          <NewsHomeColumn posts={posts} />
        </div>
      </div>

      <NewsContinued posts={postsContinued} />

      <VideoSection title="" description="" video={video} />

      <div className={styles.MeetOurClientsWrapper}>
        <MeetOurClients showLogos={false} />
      </div>

      {memberCalloutSection && (
        <SocialMembership
          title={memberCalloutSection.title}
          subtitle={memberCalloutSection.subtitle}
          description={memberCalloutSection.description}
          url={memberCalloutSection.callToActionUrl}
          image={memberCalloutSection.image.url}
          buttonText={memberCalloutSection.callToActionText}
        />
      )}

      {locationCalloutSection && (
        <div className={styles.PreleasingWrapper}>
          <CalloutSection {...locationCalloutSection} />
        </div>
      )}
    </>
  );
}

export async function getStaticProps() {
  try {
    const [
      articles,
      solutions,
      video,
      memberCalloutSection,
      locationCalloutSection,
      seo
    ] = await Promise.all([
      getHomePageBlogArticles(),
      getSolutions(),
      getAsset("BmezNjCAwnQccXF4E9i5W"),
      getCalloutSection("membership"),
      getCalloutSection("homepage"),
      getSEOPageProps({ resolvedUrl: "/" })
    ]);

    return {
      props: {
        articles,
        solutions,
        video,
        memberCalloutSection,
        locationCalloutSection,
        seo
      },
      revalidate: config.ISR_TIMING
    };
  } catch (e) {
    console.log(e.message);
  }
}
