import OfferingListItem from "../offering-list-item";

const SolutionListItem = ({ solution }) => {
  return (
    <OfferingListItem
      href={`/solutions/${solution.slug}`}
      name={solution.name}
      mobileName={solution.mobileName}
      subtitle={solution.subtitle}
      image={solution.image}
      roomSize={solution.roomSize}
    />
  );
};

export default SolutionListItem;
