import clsx from "clsx";
import { format, parseISO } from "date-fns";
import Image from "next/image";
import Link from "next/link";

import styles from "./news-post-item.module.scss";

const NewsPostItem = ({ className, post, medium, showByline }) => {
  let width = 800;
  let height = 800 / (375 / 300);

  if (medium) {
    width = 396;
    height = 283;
  }

  return (
    <Link href={`/news/${post.slug}`} passHref>
      <a className={className}>
        <div
          className={clsx(
            styles.NewsPostItem,
            medium && styles.NewsPostItemMedium
          )}
        >
          <div className={styles.Image}>
            <Image
              src={post.featuredImage.url}
              alt={post.title}
              width={width}
              height={height}
              layout="responsive"
              objectFit="cover"
            />
          </div>

          <div className={styles.PostInfo}>
            <div className={styles.Category}>{post.category}</div>
            <div className={styles.Excerpt}>
              <h3 className={styles.Title}>{post.title}</h3>
              {showByline && (
                <div className={styles.Byline}>
                  <span className={styles.Author}>{post.author}</span>
                  <span className={styles.Divider}> | </span>
                  <span className={styles.Date}>
                    {format(parseISO(post.date), "MMMM d, Y")}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default NewsPostItem;
