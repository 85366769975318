import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";

import Button from "../button";

import styles from "./callout-section.module.scss";

const CalloutSection = ({
  title,
  description,
  image,
  callToActionUrl,
  callToActionText
}) => {
  return (
    <div className={styles.CalloutSection}>
      <div className={clsx("container", styles.Wrapper)}>
        <div className={styles.DesktopImage}>
          <Image
            src={image.url}
            alt={image.description}
            width="540"
            height="288"
            layout="responsive"
          />
        </div>
        <div className={styles.Content}>
          <h2>{title}</h2>

          <div className={styles.MobileImage}>
            <Image
              src={image.url}
              alt={image.description}
              width="540"
              height="288"
              layout="responsive"
            />
          </div>

          <p>{description}</p>

          <Link href={callToActionUrl} passHref>
            <a>
              <Button outline rounded small>
                {callToActionText}
              </Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CalloutSection;
