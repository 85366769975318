import clsx from "clsx";

import styles from "./heading.module.scss";

const Heading = ({ as = "h2", className, small, children }) => {
  const Tag = as;
  return (
    <Tag className={clsx(styles.Heading, small && styles.Small, className)}>
      {children}
    </Tag>
  );
};

export default Heading;
