import clsx from "clsx";
import { format, parseISO } from "date-fns";
import Image from "next/image";
import Link from "next/link";

import Button from "../button";
import NewsSlider from "../news-slider";

import styles from "./news-continued.module.scss";

const NewsContinued = ({ posts }) => {
  return (
    <div className={clsx("container", styles.NewsContinuedContainer)}>
      <div className={styles.NewsContinued}>
        <div className={styles.Continue}>
          Continued news &amp; stories
          <Link href="/news" passHref>
            <Button rounded outline small>
              View All
            </Button>
          </Link>
        </div>
        {posts.map((post) => (
          <div key={post.title} className={styles.Post}>
            <Link href={`/news/${post.slug}`} passHref>
              <a className={styles.Image}>
                <Image
                  src={post.featuredImage.url}
                  alt={post.title}
                  layout="fixed"
                  width="77"
                  height="110"
                  objectFit="cover"
                />
              </a>
            </Link>
            <div className={styles.Info}>
              <Link href={`/news/${post.slug}`} passHref>
                <a>
                  <h3 className={styles.Title}>{post.title}</h3>
                </a>
              </Link>
              <div className={styles.Date}>
                {format(parseISO(post.date), "MMMM d, y")}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.NewsContinuedMobile}>
        <h3>Continued news &amp; stories</h3>
        <NewsSlider
          posts={posts.map((post) => ({
            ...post,
            byline: format(parseISO(post.date), "MMMM d, y")
          }))}
        />
      </div>
    </div>
  );
};

export default NewsContinued;
