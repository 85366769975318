import clsx from "clsx";
import Link from "next/link";

import Button from "../button";

import styles from "./social-membership.module.scss";

const SocialMembership = ({
  title,
  subtitle,
  description,
  url,
  image,
  buttonText
}) => {
  return (
    <div className={styles.SocialMembership}>
      <div className={clsx("container", styles.Wrapper)}>
        <div className={styles.Info}>
          <h3>{subtitle}</h3>

          <h2>{title}</h2>

          <div className={styles.MobileImage}>
            <img src={image} alt="Membership" loading="lazy" />
          </div>

          <p>{description}</p>

          <Link href={url} passHref>
            <a>
              <Button outline rounded small>
                {buttonText}
              </Button>
            </a>
          </Link>
        </div>
        <div className={styles.DesktopImage}>
          <img src={image} alt="Membership" />
        </div>
      </div>
    </div>
  );
};

export default SocialMembership;
